import { v4 as uuid } from 'uuid';
import { userSession } from './auth';
import { Storage } from '@stacks/storage';

const TASKS_FILENAME = 'tasks.json';

export const setupGaia = async () => {
    var userData = userSession.userData
    if(!userData) {
        userData = userSession.loadUserData()
    }
    userData.hubUrl = 'https://ec2-44-203-232-207.compute-1.amazonaws.com'
    const sessionData = userSession.store.getSessionData();
    sessionData.userData = userData
    userSession.store.setSessionData(sessionData)
}

/**
 * @typedef {Object} Task
 * @property {boolean} complete
 * @property {string} value
 * @property {string} id
 */

// @type {Task[]}
export const defaultTasks = [
  {
    complete: false,
    value: '',
    id: uuid(),
  },
];

/**
 * Save tasks to Gaia
 * @param {UserSession} userSession
 * @param {Todo[]} tasks
 * @param {boolean} isPublic
 */
export const saveTasks = async (userSession, tasks, isPublic) => {
  const storage = new Storage({ userSession });
  await storage.putFile(TASKS_FILENAME, JSON.stringify({ tasks, isPublic }), {
    encrypt: !isPublic,
    dangerouslyIgnoreEtag: true,
  });
};

/**
 * Fetch tasks for a specific user. Omit the `username` argument to fetch the current user's tasks.
 *
 * If no tasks are found, and no username is provided, then the default tasks are returned.
 * If tasks are found, we check to see if they are public.
 * @param {import("@stacks/auth").UserSession} userSession
 * @param {string} username - the username to fetch tasks for. Omit this argument or set it to an empty string
 * to fetch the current user's tasks.
 * @returns {{ tasks: Task[] | null, public: boolean }}
 */
export const fetchTasks = (userSession) => {
  try {
    const storage = new Storage({ userSession });
    /** @type {string} raw JSON stored in Gaia */
    const tasksJSON = storage.getFile(TASKS_FILENAME, {
      decrypt: false,
    });
    if (tasksJSON) {
      const json = JSON.parse(tasksJSON);
      if (json.isPublic) {
        return {
          tasks: json.tasks,
          public: true,
        };
      } else {
          const decrypted = JSON.parse( userSession.decryptContent(tasksJSON));
          return {
            tasks: decrypted.tasks,
            public: false,
          };
      }
    } else {
      return {
        tasks: defaultTasks,
        public: false,
      };
    }
  } catch (error) {
    return {
        tasks: defaultTasks,
      };
    }
};
