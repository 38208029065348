import { StyleSheet, Image } from 'react-native';
import { Person } from '@stacks/profile';

import Colors from '../constants/Colors';
import { MonoText } from '../components/StyledText';
import { Text, View } from '../components/Themed';

import { userSession } from '../stacks/auth';

function person() {
    var userData = userSession.userData
    if(!userData) {
        userData = userSession.loadUserData()
    }
    return new Person(userData.profile)
}

function userName() {
    const name = person().name()
    return name === null ? 'Anonymous' : name
}

function userAvatar() {
    const url = person().avatarUrl()
    return url === null  ? "https://s3.amazonaws.com/onename/avatar-placeholder.png" : url
}

export default function ProfileScreen() {
  return (
    <View>
      <Image style={styles.image} source={userAvatar()} />
      <View style={styles.getStartedContainer}>
        <View
          style={[styles.codeHighlightContainer, styles.homeScreenFilename]}
          darkColor="rgba(255,255,255,0.05)"
          lightColor="rgba(0,0,0,0.05)">
          <MonoText>{userName()}</MonoText>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    marginBottom: 40,
    width: 100,
    height: 100,
  },
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  getStartedText: {
      fontSize: 20,
      lineHeight: 24,
      textAlign: 'center',
      fontWeight: 'bold',
    },

  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },

  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
});
