import React from 'react';
import { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  StatusBar,
} from "react-native";
import { MonoText } from '../components/StyledText';

import { authenticate } from '../stacks/auth';
import { userSession } from '../stacks/auth';

export default function SigninScreen(){
  	return (
		<View style={styles.container}>
        		  <Image style={styles.image} source={require("../assets/images/web-icon_192x192.png")} />
                  <MonoText style={styles.titleText}>sauFoto</MonoText>
        		  <TouchableOpacity style={styles.loginBtn}
        			  onPress={() => authenticate()}>
        			<Text style={styles.loginText}>Signin</Text>
        		  </TouchableOpacity>
  		</View>
		);
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ede7f6",
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    marginBottom: 40,
    width: 100,
    height: 100,
    borderRadius: 20
  },

  titleText: {
      color: "#4527a0",
      fontSize: 32,
      lineHeight: 24,
      textAlign: 'center',
      fontWeight: 'bold',
    },

  loginText: {
      color: "#311b92",
      fontSize: 17,
      lineHeight: 24,
      textAlign: 'center',
      fontWeight: 'bold',
    },

  loginBtn: {
    width: "20%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#b388ff",
  },
});